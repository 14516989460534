import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECURE_STORE_KEY; // Replace with your secure key from environment variables.

const secureStorage = new SecureStorage(localStorage, {
  hash: function (key) {
    // console.log(key, 'keyyyyyyy');
    return CryptoJS.SHA256(key).toString();
  },
  encrypt: function (data) {
    // console.log(data, 'dataaaaaaaa');
    // console.log(SECRET_KEY, 'SECRET_KEY');
    if (data) {
      return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
    }
  },
  decrypt: function (data) {
    try {
      if (data) {
        return CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      console.error('Decryption failed:', error);
      return null; // Return null for invalid data
    }
  },
});

export default secureStorage;
