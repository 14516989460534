import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import back from '@assets/pictures/svgs/back-modal-black.svg';
// import close from '@assets/pictures/svgs/close.svg';
import { withNamespaces } from 'react-i18next';
// import { setSelectedCinema, setSelectedCity } from '@store/movies/movieActions';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import { useHistory, useLocation } from 'react-router-dom';
// import Spinner from '@components/partials/Spinner';
import { otpValidation } from '@helper/formatting';
import { motion } from 'framer-motion';
import AuthService from '@apiService/AuthService';
import { startTimer, resetTimer } from '../../../store/auth/authActions';
const EmailVerificationPopup = (props) => {
  const { t, closeFunc } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  const { pathname } = useLocation();
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser, userHistory } = loginDetails;
  const state = useSelector((state) => state);
  const {
    auth: {
      loginOtpError,
      timer: { seconds, minutes },
      timerCompleted,
    },
  } = state;

  const cinemas = useSelector((state) => state.movies.cinemas);
  const selectedCity = useSelector((state) => state.movies.selectedCity);
  const cities = useSelector((state) => state.movies.cities);
  const [isEditable, setIsEditable] = useState(false);

  const [verifyBtn, setverifyBtn] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState('');
  const [OTPMessage, setOTPMessage] = useState('');
  const [changedEmail, setChangedEmail] = useState('');
  const [changeEmailError, setChangeEmailError] = useState('');
  const [verification_id, setverificationId] = useState('');

  const [loading, setLoading] = useState(false);
  const [closeModal, setCloseModal] = useState(true);

  const onSaveEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(changedEmail)) {
      setChangeEmailError('Invalid email address');
    } else {
      setChangeEmailError('');
      setIsEditable(false);
    }
  };
  const backFunc = async () => {
    setverifyBtn(false);
    setChangeEmailError('');
    dispatch(resetTimer());
  };
  const onUpdateEmail = async () => {
    setIsEditable(true);
    setChangeEmailError('');
    // dispatch(resetTimer());
  };
  const onClickVerifyEmail = async (e) => {
    e.preventDefault();
    let payload = {
      emailId:
        !isEditable && changedEmail ? changedEmail : loggedInUser?.cust_email,
      cust_id: loggedInUser?.cust_id,
    };

    const { data } = await AuthService.sendCustomerEmailVerification(payload);
    setLoading(true);
    if (data?.status) {
      setLoading(false);
      dispatch(startTimer());
      setverifyBtn(true);
      setverificationId(data?.verification_id);
      setChangeEmailError('');
    } else if (!data?.status) {
      setLoading(false);
      setChangeEmailError(data?.message);
    }
  };

  const onClickVerifyOtp = async (e) => {
    e.preventDefault();
    let payload = {
      verification_id: verification_id,
      otp_email: verifyOTP,
      cust_id: loggedInUser?.cust_id,
    };

    const { data } = await AuthService.verifyCustomerEmailOtp(payload);
    setLoading(true);
    if (data?.status) {
      setLoading(false);
      setCloseModal(false);
      setChangeEmailError('');
      window.location.reload();
    } else if (!data?.status) {
      setLoading(false);
      setChangeEmailError('Otp Not Matched');
    }
  };
  const onResentVerfiyOtp = async () => {
    let payload = {
      verification_id: verification_id,
      email: !isEditable && changedEmail ? changedEmail : loggedInUser?.cust_email,
    };

    const { data } = await AuthService.resendEmailOTP(payload);
    if (data?.status) {
      dispatch(resetTimer());
      dispatch(startTimer());
    } else {
      setOTPMessage(data?.message);
    }
  };

  return (
    <>
      {closeModal && (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          className="show-details-popup"
          centered
          backdrop="static"
          onHide={() => closeFunc(false)}
        >
          <section className="wrapper container-fluid text-center">
            {!verifyBtn && (
              <>
                <div>
                  <h4 style={{ fontWeight: 'bold' }}>Verify Email</h4>
                </div>

                <p className="my-3" style={{ lineHeight: '25px' }}>
                  An OTP email will be sent to{' '}
                  <span className="email_id">
                    {!isEditable && changedEmail
                      ? changedEmail
                      : loggedInUser?.cust_email}{' '}
                  </span>
                  once you click on "Verify Your Email"
                </p>

                {isEditable && !timerCompleted && (
                  <>
                    <div className="d-block">
                      <div className="d-flex otp-input-new mb-1 justify-content-center">
                        <input
                          type="email"
                          placeholder={t('Enter your Email Id')}
                          value={changedEmail}
                          onChange={(e) => setChangedEmail(e.target.value)}
                          className="email_change_input"
                        />
                        <button
                          // onClick={() => setIsEditable(false)}
                          onClick={onSaveEmail}
                          className="save_otp_btn"
                        >
                          Save
                        </button>
                      </div>
                      {changeEmailError && (
                        <p
                          className="error"
                          style={{ textAlign: 'left', fontSize: '14px' }}
                        >
                          {changeEmailError}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {changeEmailError && (
                  <p
                    className="error"
                    style={{ textAlign: 'center', fontSize: '14px' }}
                  >
                    <span className="exclamatory">&#10071;</span>
                    {changeEmailError}
                  </p>
                )}
                <div
                  className="d-flex justify-content-center align-items-center mt-3"
                  style={{ gap: '20px' }}
                >
                  <button
                    // onClick={() => setIsEditable(!isEditable)}
                    onClick={onUpdateEmail}
                    className="btn-main"
                  >
                    Update your Email
                  </button>

                  <button
                    className={`btn-main ${loading && `loader-text`}`}
                    // onClick={() => setverifyBtn(true)}
                    onClick={onClickVerifyEmail}
                  >
                    Verify Your Email
                  </button>
                </div>
              </>
            )}

            {verifyBtn && (
              <>
                {!timerCompleted && (
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                      src={back}
                      className={`back-btn-main`}
                      onClick={backFunc}
                      alt=""
                      style={{ cursor: 'pointer', width: '12px' }}
                    />
                  </div>
                )}
                {!timerCompleted && (
                  <p className="msg my-3 text-left">
                    Enter OTP sent to{' '}
                    {!isEditable && changedEmail
                      ? changedEmail
                      : loggedInUser?.cust_email}{' '}
                    for Verification
                  </p>
                )}
                <div className="otp-input mb-1">
                  {!timerCompleted && (
                    <input
                      type="text"
                      value={verifyOTP}
                      onChange={(e) => {
                        if (otpValidation(e.target.value)) {
                          setVerifyOTP(e.target.value);
                        }
                      }}
                    />
                  )}
                  {!timerCompleted && (
                    <div
                      className={`d-flex align-items-center mt-2 ${
                        changeEmailError
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      }`}
                    >
                      {changeEmailError && (
                        <p
                          className="error"
                          style={{ textAlign: 'left', fontSize: '14px' }}
                        >
                          {changeEmailError}
                        </p>
                      )}
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="text-right"
                      >
                        {minutes.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                        :
                        {seconds.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                        &nbsp;
                      </motion.p>
                    </div>
                  )}

                  {timerCompleted && (
                    <>
                      {' '}
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <p>{t(`otp_message`)}&nbsp;</p>
                        <button
                          // className={`btn-basic ml-2 ${
                          //   loadingResendOtp ? 'loader-text' : ''
                          // }`}
                          className={`btn-basic ml-2`}
                          onClick={() => {
                            setVerifyOTP('');
                            // onClickResend && onClickResend();
                            onResentVerfiyOtp();
                          }}
                          // className={`btn-main mx-auto ${!allowResend && `inactive`}`}
                        >
                          {t(`Resend`)}
                        </button>
                      </div>
                    </>
                  )}
                </div>

                {OTPMessage != '' && <p className="error">{OTPMessage}</p>}

                {!timerCompleted && (
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className={`btn-main mt-2 ${loading && `loader-text`}`}
                      onClick={onClickVerifyOtp}
                    >
                      {t(`Confirm`)}
                    </button>
                  </div>
                )}
              </>
            )}
          </section>
        </Modal>
      )}
    </>
  );
};

export default withNamespaces()(EmailVerificationPopup);
